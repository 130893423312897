import React, { Component } from 'react';
import NotificationsList from './Notifications/NotificationsList';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <NotificationsList/>
      </div>
    );
  }
}

export default App;
