import React, {Component} from 'react';
import Popover from 'react-awesome-popover';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';

export default class ShareMenu extends React.Component {
    
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  
  handleClick = (e) => {
        this.setState({open: !this.state.open});
    }

    handleClose = (e) => {
        this.setState({open: false});
    }
  
  render() {
      return (
        <Popover
        placement='top'
        >
          <i id="a-c-share-icon" className="fa fa-share"> </i>
          <div className='share-menu'>
            <FacebookShareButton url={this.props.url} >
                <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={this.props.url} >
                <TwitterIcon size={32} round />
            </TwitterShareButton>
            <LinkedinShareButton url={this.props.url}>
                <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <WhatsappShareButton url={this.props.url}>
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <EmailShareButton url={this.props.url} >
                <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
        </Popover> 
      );
    }
  }