import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import ContentLoader from "react-content-loader";
import ShareMenu from './ShareMenu';

const FeedbackComponent = (props) => {
  return (
    <div className="a-c-f">
      <textarea className="a-c-f-i" maxLength="1000" name="feedback" placeholder="Send us your feedback"></textarea>
    </div>
  )
}

const LoadingAnimation = (props) => {
  return (
  <ContentLoader
    height={350}
    width={400}
    speed={1}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    ariaLabel={false}
  >
    <rect x="0" y="15" rx="4" ry="4" width="275" height="8" />
    <rect x="0" y="40" rx="4" ry="4" width="400" height="15" />
    <rect x="0" y="70" rx="4" ry="4" width="350" height="15" />
    <rect x="5" y="100" rx="5" ry="5" width="390" height="150" />
    <rect x="0" y="265" rx="4" ry="4" width="400" height="15" />
    <rect x="0" y="295" rx="4" ry="4" width="350" height="15" />
  </ContentLoader>
  )
}

class NotificationsList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: null,
      data : [],
      toggle: false,
    };
  }

  getNotifications = () => {
    this.setState({ loading: true });
    const url = 'https://acimconnect.com/DesktopModules/XModPro/Feed.aspx?xfd=UpdatesNotifications&pid=0';
    axios.get(url)
    .then((response) => {
      const data = response.data;
      this.setState({ data });
      this.setState({ loading: false });
    })
    .catch( (error) => {
      console.log(error);
    });    
  }
  
  toggleSidepanel = () => {
    const currentState = this.state.toggle;
    this.setState({ toggle: !currentState });
  }

  renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
  
  componentDidMount() {
    this.getNotifications();
    if (Notification.permission !== "granted") {
      document.getElementById('a-c-ask').style.display='block';
    }
    else {
      document.getElementById('a-c-ask').style.display='none';
    }
  }

  componentDidUpdate() {
    if (Notification.permission !== "granted") {
      document.getElementById('a-c-ask').style.display='block';
    }
    else {
      document.getElementById('a-c-ask').style.display='none';
    }
  }
  
  render() {
    return (
      <div id="myNotifications">
      <div id="_stickybox_" className={`panel panel-primary col-xs-11 col-sm-6 col-md-4 col-lg-3 ${this.state.toggle ? 'visible' : ''}`}>
          <div className="a-c-h panel-heading">
            <h1 className="a-c-t panel-title">
              What's new at ACIM
              <span className="pull-right">
                <span id="a-c-close" onClick={this.toggleSidepanel}><i className="fa fa-close"> </i></span>
              </span>
            </h1>
          </div>
          <div className="a-c-panel-body panel-body">
            <p>&nbsp;</p>
          </div>
          <ul className="a-c-l list-group">
            <li id="a-c-ask" className="list-group-item a-c-i">
              <img src="https://acimconnect.com/portals/0/images/footer-logo.png" className="a-c-logo"/>
              <p> We'd like to show you notifications for the latest news and updates.</p>
            
            <div className="a-c-p a-c-f">
              <div className="pull-right">
                <a href="" className="a-c-nt">NO, THANKS</a>&nbsp;
                <a id="my-notification-button" className="btn btn-sm btn-primary" href="">ALLOW</a>
              </div>
            </div>
            </li>
            {
              (this.state.loading === true )
              ?
              <>
                <li className="list-group-item a-c-i">
                  <LoadingAnimation/>
                </li>
                <li className="list-group-item a-c-i">
                  <LoadingAnimation/>
                </li>
              </>
              :
              <>
                {(this.state.data).map((item,index)=>{
                  return (
                    <li className="list-group-item a-c-i" key={index}>
                      {(
                        dayjs().diff(dayjs(item.PublishDate), 'day') <= 5 
                        ?
                        <>
                            <span className="pull-left a-c-b badge">NEW</span>
                            <span>&nbsp;</span>
                        </>
                        :
                        null
                      )}
                      <span className="a-c-d">{dayjs(item.PublishDate).format('MMMM DD, YYYY')}</span>
                      <span className="pull-right">
                      {
                      item.Url != null && 
                      <ShareMenu
                        url={item.Url}
                      >
                      </ShareMenu>
                      }
                      </span>
                      <h2 className="a-c-n-t">{ item.Url != null ? <a href={item.Url}>{item.Title}</a> : item.Title }</h2>
                      {this.renderHTML(item.Summary)}
                      { item.AllowFeedback ? <FeedbackComponent/> : null }
                    </li>
                  )
                })}
              </>
            }
          </ul>
        </div>
        <div id="a-c-icon" onClick={this.toggleSidepanel} className={this.state.toggle ? 'toggle' : ''}>
          <span className="a-c-i-action">
            <i className="bell fa fa-bell"> </i>
          </span>    
        </div>
      </div>
    );
  }
}

export default NotificationsList;